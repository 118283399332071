@import '../_var.less';

@atom: Toaster;

.@{atom} {
    @size: 100px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    width: calc(100vw - @dim-sm * 3);
    left: 0;
    border-radius: 10px;
    height: 62px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    z-index: @z-toaster;
    background: white;
    padding: @dim-sm;
    margin: @dim-sm;
    transform: translateY(0);
    opacity: 1;
    cursor: pointer;
    transition: transform .2s ease-in,
                opacity .2s ease-in;

    &.@{atom}--hidden {
        transform: translateY(@size);
        opacity: 0;
    }

    .@{atom}__message {
        font-weight: 800;
        font-size: @font-xs;
    }

    .@{atom}__icon {
        max-width: 22px;
        margin-right: @dim-xs;
    }

    &[data-type="nosignal"] {
        display: flex !important;
    }
}